const FeatureTag = Object.freeze({
    StockSignal: {
        value: "stock_signal"
    },
    OptionSignal: {
        value: "option_signal"
    },
    CryptoSignal: {
        value: "crypto_signal"
    },
    Analysis: {
        value: "analysis"
    },
    PremiumScreener: {
        value: "premium_screener"
    },
    Indicator: {
        value: "indicator"
    },
});


export {FeatureTag};
