import {initializeApp} from 'firebase/app';
import {getAnalytics} from "firebase/analytics";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {
    UserContextKeyAccessToken,
    UserContextKeyUser,
    UserReduceActionKeySignOutSuccess
} from "../context/UserContextKey";

const firebaseConfig = {
    apiKey: "AIzaSyCDqHS2sQj45zcxjFpkNjHcZWrzK_lXICA",
    authDomain: "avadarfinancial.firebaseapp.com",
    databaseURL: "https://avadarfinancial.firebaseio.com",
    projectId: "avadarfinancial",
    storageBucket: "avadarfinancial.appspot.com",
    messagingSenderId: "834245074962",
    appId: "1:834245074962:web:264595cf28ec6479d2f8cb",
    measurementId: "G-87X1GYWPPM"
};

const firebase_app = initializeApp(firebaseConfig);
const firebase_analytics = getAnalytics(firebase_app);
export {firebase_app, firebase_analytics, getFirebaseUser};

const auth = getAuth();

onAuthStateChanged(auth, (user) => {
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        user.getIdToken().then((tk) => {
            const token = tk;
            console.log(`user login id token: ${token}`);
        })
        console.log(`user login ${uid} display: ${user.displayName}`);
    } else {
        // User is signed out
        console.log(`user logout`);
    }
});


const getFirebaseUser = () => {
    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                resolve(user)
            } else {
                reject(`unlogin`)
            }
        });
    })
}

const observerLogoutIfNeeded = ({dispatch, history}) => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            console.log("user is Login")
        } else {
            console.log("user kick out")
            localStorage.removeItem(UserContextKeyAccessToken);
            localStorage.removeItem(UserContextKeyUser)
            dispatch({
                type: UserReduceActionKeySignOutSuccess,
                data: {
                    isAuthenticated: false,
                    access_token: null,
                    user: null
                }
            });
            history.push("/login");
        }
    });
}

export {observerLogoutIfNeeded};
