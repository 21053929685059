import React from "react";
import {
    createUserWithEmailAndPassword,
    getAuth,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut as firebaseSignOut,
    updateProfile
} from "firebase/auth";
import {
    UserContextKeyAccessToken,
    UserContextKeyUser,
    UserReduceActionKeyLoginFailure,
    UserReduceActionKeyLoginSuccess,
    UserReduceActionKeySignOutSuccess,
} from "./UserContextKey";
import {user_manager} from "../biz/User/UserManager";
import {bounce_ape_analytics} from "../common/Analytics/Analytics";

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();
const auth = getAuth();

function userReducer(state, action) {
    switch (action.type) {
        case UserReduceActionKeyLoginSuccess:
            return {...state, ...action.data, isAuthenticated: true};
        case UserReduceActionKeySignOutSuccess:
            return {...state, ...action.data, isAuthenticated: false};
        case UserReduceActionKeyLoginFailure:
            return {...state, ...action.data};
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function UserProvider({children}) {
    var [state, dispatch] = React.useReducer(userReducer, {
        isAuthenticated: !!localStorage.getItem(UserContextKeyAccessToken),
        access_token: localStorage.getItem(UserContextKeyAccessToken),
        user: localStorage.getItem(UserContextKeyUser)
    });

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
}

function useUserState() {
    var context = React.useContext(UserStateContext);
    if (context === undefined) {
        throw new Error("useUserState must be used within a UserProvider");
    }
    return context;
}

function useUserDispatch() {
    var context = React.useContext(UserDispatchContext);
    if (context === undefined) {
        throw new Error("useUserDispatch must be used within a UserProvider");
    }
    return context;
}

export {UserProvider, useUserState, useUserDispatch, loginUser, signOut, signUp, resetPasswordEmail};

// ###########################################################

function loginUser(dispatch, email, password, history, setIsLoading, setError) {
    setError(false);
    setIsLoading(true);
    bounce_ape_analytics.logEvent({event: "login_start"});
    if (!!email && !!password) {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                bounce_ape_analytics.logEvent({event: "login_success"});
                // Signed in
                const user = userCredential.user;
                localStorage.setItem(UserContextKeyAccessToken, user.access_token);
                localStorage.setItem(UserContextKeyUser, user)
                // user_manager.fetchFeatureTags({dispatch})
                setError(null)
                setIsLoading(false)
                dispatch({
                    type: UserReduceActionKeyLoginSuccess,
                    data: {
                        isAuthenticated: true,
                        access_token: user.access_token,
                        user: user
                    }
                })
                history.push('/app/stock_signal')
                console.log(`login success user: ${user}`)
                // ...
            })
            .catch((error) => {
                bounce_ape_analytics.logEvent({event: "login_fail"});
                // dispatch({ type: UserReduceActionKeyLoginFailure });
                setError(true);
                setIsLoading(false);
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(`code: ${errorCode} errorMessage: ${errorMessage}`)
            });
    } else {
        // dispatch({ type: UserReduceActionKeyLoginFailure });
        setError(true);
        setIsLoading(false);
    }

}

function signUp(dispatch, email, password, name, setIsLoading, setError) {
    bounce_ape_analytics.logEvent({event: "register_start"});
    setError(false);
    setIsLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            bounce_ape_analytics.logEvent({event: "register_success"});
            const user = userCredential.user;
            updateProfile(auth.currentUser, {
                displayName: name
            }).then(() => {
            }).catch((error) => {
            });
            dispatch({
                type: UserReduceActionKeyLoginSuccess,
                data: {
                    isAuthenticated: true,
                    access_token: user.access_token,
                    user: user
                }
            })
            setError(false);
            setIsLoading(true);
            console.log("success");
            console.log(userCredential);
        })
        .catch((error) => {
            bounce_ape_analytics.logEvent({event: "register_fail"});
            // dispatch({ type: UserReduceActionKeyLoginFailure })
            setError(true);
            setIsLoading(false);
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(`code: ${errorCode} errorMessage: ${errorMessage}`)
        })
}


function resetPasswordEmail(dispatch, email, history, setIsLoading, setError) {
    setError(false);
    setIsLoading(true);
    sendPasswordResetEmail(auth, email)
        .then(() => {
            // Password reset email sent!
            console.log("success");
            setError(false);
            setIsLoading(false);
        }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setError(true);
        setIsLoading(false);
        console.log(`code: ${errorCode} errorMessage: ${errorMessage}`)
    });
}

function signOut(dispatch, history) {
    firebaseSignOut(auth).then(() => {
        console.log(`sign out success`)
        localStorage.removeItem(UserContextKeyAccessToken);
        localStorage.removeItem(UserContextKeyUser)
        dispatch({
            type: UserReduceActionKeySignOutSuccess,
            data: {
                isAuthenticated: false,
                access_token: null,
                user: null
            }
        });
        history.push("/login");
    }).catch((error) => {
        console.log(`sign out error: ${error}`)
    });
}
