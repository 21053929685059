import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "2rem",
        paddingBottom: "2rem",
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: '#fff',
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: '#fff',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: '#fff',
    }
}));

export default useStyles;