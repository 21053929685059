import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";


const init_sentry = () => {
    Sentry.init({
        dsn: "https://7f994371266e4c6ea9b4f5836891307b@o291548.ingest.sentry.io/6066417",
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}


export {init_sentry}
