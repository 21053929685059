import {ToastContainer} from "react-toastify";
import React from "react";
import useStyles from "./styles";
import CloseButton from "./CloseButton";
import "react-toastify/dist/ReactToastify.css";

function WrapToastContainer() {
    const classes = useStyles();
    return <ToastContainer
        toastStyle={{backgroundColor: "transparent"}}
        className={classes.toastsContainer}
        closeButton={
            <CloseButton className={classes.notificationCloseButton} />
        }
        closeOnClick={false}
        progressClassName={classes.notificationProgress}
    />
}

export default WrapToastContainer;
