// ES6 module syntax
import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
    en:{
        profileSubscriptionsEmpty: "No subscription record",
        brand: "BounceApe",
        homeWebsite: "BounceApe.com",
    },
});


export {strings};
