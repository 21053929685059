const EventKey = {
    pageShow: "page_show",
    subscribeStart: "cp_subscribe_start",
    subscribeSuccess: "cp_subscribe_success",
    subscribeFail: "cp_subscribe_fail",
    subscribeCancel: "cp_subscribe_cancel",
};

const PropertyKey = {
    name: "name"
};

export {EventKey, PropertyKey};
