import {firebase_analytics} from "../../firebase/FirebaseInitializer";
import {amplitude_analytics} from "./Amplitude/Amplitude";
import {logEvent} from "firebase/analytics";
import ReactPixel from 'react-facebook-pixel';
import {EventKey} from "./AnalyticsEventKey";

class BounceApeAnalytics {

    logEvent = ({event, properties = {}}) => {
        if (event === EventKey.pageShow) {
            ReactPixel.pageView()
            ReactPixel.track('ViewContent')
            console.log("analytics event: page show")
        }
        logEvent(firebase_analytics, event, properties);
        amplitude_analytics.logEvent(event, properties)
        console.log(`analytics event: ${event} properties: ${JSON.stringify(properties)}`);
    }
}


const bounce_ape_analytics = new BounceApeAnalytics();

export {bounce_ape_analytics};
