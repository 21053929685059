import ReactPixel from 'react-facebook-pixel';

const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};

const init_facebook = () => {
    console.log("init facebook sdk");
    ReactPixel.init('430482805456745', advancedMatching, options);
};

export {init_facebook};
