import React, {lazy, Suspense, useEffect} from "react";
import {Route, Switch, withRouter,} from "react-router-dom";
import classnames from "classnames";
import {Box, Breadcrumbs, CircularProgress, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'
//icons
import {
    mdiFacebook as FacebookIcon,
    mdiTwitter as TwitterIcon,
    mdiDiscord as DiscordIcon
} from '@mdi/js'
// styles
import useStyles from "./styles";
// components
import Header from "../Header";
import Sidebar from "../Sidebar";
// pages
// context
import {useLayoutState} from "../../context/LayoutContext";
import {matchRoutes} from "react-router-config";
import {user_manager} from "../../biz/User/UserManager";
import {useUserDispatch, useUserState} from "../../context/UserContext";
import {FeatureTagProvider, useFeatureTagDispatch} from "../../context/FeatureTagContext";
import {observerLogoutIfNeeded} from "../../firebase/FirebaseInitializer";
// paypal
// const Dashboard = lazy(() => import('../../pages/dashboard'));
const StockSignal = lazy(() => import("../../pages/StockSignal/StockSignal"));
const OptionSignal = lazy(() => import("../../pages/OptionSignal/OptionSignal"));
const CryptoSignal = lazy(() => import("../../pages/CryptoSignal/CryptoSignal"));
const WatchList = lazy(() => import("../../pages/WatchList/WatchList"));
const PushList = lazy(() => import("../../pages/PushList/PushList"));
const PremiumScreener = lazy(() => import("../../pages/PremiumScreener/PremiumScreener"));
const Screener = lazy(() => import("../../pages/Screener/Screener"));
const Analysis = lazy(() => import("../../pages/Analysis/Analysis"));
const SignalSubscription = lazy(() => import("../../pages/Subscription/SignalSubscription"));
const AnalysisSubscription = lazy(() => import("../../pages/Subscription/AnalysisSubscription"));
const ScreenerResult = lazy(() => import("../../pages/ScreenerResult/ScreenerResult"));
const ScreenerSubcategory = lazy(() => import("../../pages/ScreenerSubcategory/ScreenerSubcategory"));
const AnalysisResult = lazy(() => import("../../pages/AnalysisResult/AnalysisResult"));
// const Quote = lazy(() => import("../../pages/Quote/Quote"));
// const Charts = lazy(() => import("../../pages/charts"));
// const Icons = lazy(() => import("../../pages/icons"));
// const Tables = lazy(() => import("../../pages/tables"));
// const Notifications = lazy(() => import("../../pages/notifications"));
// const Typography = lazy(() => import("../../pages/typography"));

const rootPath = '/app'

const routes = [
    {
        path: `${rootPath}/stock_signal/`,
        breadcrumbName: 'Stock Signal',
        "routes": [
            {
                path: `${rootPath}/stock_signal/quote`,
                breadcrumbName: 'Quote',
            }
        ]
    },

    {
        path: `${rootPath}/premium_screener/`,
        breadcrumbName: 'Premium Screener',
        "routes": [
            {
                path: `${rootPath}/premium_screener/result`,
                breadcrumbName: 'Screener Result',
            }
        ]
    },
    {
        path: `${rootPath}/screener/`,
        breadcrumbName: 'Screener',
        "routes": [
            {
                path: `${rootPath}/screener/subcategory`,
                breadcrumbName: 'Subcategory',
                routes: [
                    {
                        path: `${rootPath}/screener/subcategory/result`,
                        breadcrumbName: 'Screener Result',
                    }
                ]
            },
            {
                path: `${rootPath}/screener/result`,
                breadcrumbName: 'Screener Result',
            }
        ]
    },

]

const generateBreadcrumbs = ({locationPath, onMatchedRoutes}) => {
    console.log(`location path: ${locationPath}`);
    let matchedRoutes = matchRoutes(routes, locationPath.pathname);
    if (typeof onMatchedRoutes === 'function') {
        matchedRoutes = onMatchedRoutes(matchedRoutes);
    }
    return (
        <Breadcrumbs aria-label="breadcrumb">
            {matchedRoutes.map((matchRoute, i) => {
                const {path, breadcrumbName} = matchRoute.route;
                const isActive = path === locationPath.pathname;

                return isActive ? (
                        <Link
                            underline="hover"
                            color="inherit"
                            href={`#${path}`} /// trick
                        >
                            {breadcrumbName}
                        </Link>
                    ) :
                    <Link
                        underline="hover"
                        color="inherit"
                        href={`#${path}`}  /// trick
                    >
                        {breadcrumbName}
                    </Link>
                    ;
            })}
        </Breadcrumbs>
    );
}


function Layout(props) {
    const classes = useStyles();
    // global
    const layoutState = useLayoutState();
    const featureTagDispatch = useFeatureTagDispatch();
    // const userDispatch = useUserDispatch();
    const {location} = props

    // observerLogoutIfNeeded({
    //     dispatch: userDispatch,
    //     history: props.history
    // })

    useEffect(() => {
        user_manager.fetchFeatureTags({dispatch: featureTagDispatch});
    }, []);
    return (
        <div className={classes.root}>
            <>
                <Header history={props.history}/>
                <Sidebar/>

                <div
                    className={classnames(classes.content, {
                        [classes.contentShift]: layoutState.isSidebarOpened,
                    })}
                >
                    <div className={classes.fakeToolbar}/>
                    {generateBreadcrumbs({locationPath: location})}

                    <Suspense fallback={<div><CircularProgress/></div>}>
                        <Switch>
                            <Route exact path="/app/stock_signal" component={StockSignal}/>
                            <Route exact path="/app/option_signal" component={OptionSignal}/>
                            <Route exact path="/app/crypto_signal" component={CryptoSignal}/>
                            <Route path="/app/notification_list" component={PushList}/>
                            <Route path="/app/watch_list" component={WatchList}/>
                            <Route exact path="/app/premium_screener" component={PremiumScreener}/>
                            <Route exact path="/app/screener" component={Screener}/>
                            <Route exact path="/app/analysis" component={Analysis}/>
                            <Route path="/app/signal_subscription" component={SignalSubscription}/>
                            <Route exact path="/app/ana_subscription" component={AnalysisSubscription}/>
                            <Route path="/app/screener/result" component={ScreenerResult}/>
                            <Route path="/app/premium_screener/result" component={ScreenerResult}/>
                            <Route exact path="/app/screener/subcategory" component={ScreenerSubcategory}/>
                            <Route path="/app/screener/subcategory/result" component={ScreenerResult}/>
                            <Route path="/app/analysis/analysis_result" component={AnalysisResult}/>

                            {/*<Route path="/app/stock_signal/quote" component={Quote}/>*/}
                            {/*<Route path="/app/dashboard" component={Dashboard}/>*/}
                            {/*<Route path="/app/typography" component={Typography}/>*/}
                            {/*<Route path="/app/tables" component={Tables}/>*/}
                            {/*<Route path="/app/notifications" component={Notifications}/>*/}

                            {/*<Route*/}
                            {/*    exact*/}
                            {/*    path="/app/ui"*/}
                            {/*    render={() => <Redirect to="/app/ui/icons"/>}*/}
                            {/*/>*/}
                            {/*<Route path="/app/ui/icons" component={Icons}/>*/}
                            {/*<Route path="/app/ui/charts" component={Charts}/>*/}
                        </Switch>
                    </Suspense>
                    <Box
                        mt={5}
                        width={"100%"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent="space-between"
                    >
                        <div>
                            <Link
                                color={'primary'}
                                href={'https://avadarfinancial.web.app/'}
                                target={'_blank'}
                                className={classes.link}
                            >
                                Sagamill
                            </Link>
                            <Link
                                color={'primary'}
                                href={'https://sagamill.web.app'}
                                target={'_blank'}
                                className={classes.link}
                            >
                                About Us
                            </Link>
                            <Link
                                color={'primary'}
                                href={'https://avadarfinancial.web.app/helpcenter'}
                                target={'_blank'}
                                className={classes.link}
                            >
                                Blog
                            </Link>
                        </div>
                        <div>
                            <Link
                                href={'https://discord.gg/A3zTMkFrg7'}
                                target={'_blank'}
                            >
                                <IconButton aria-label="discord">
                                    <Icon
                                        path={DiscordIcon}
                                        size={1}
                                        color="#6E6E6E99"
                                    />
                                </IconButton>
                            </Link>
                            {/*<Link*/}
                            {/*    href={'https://www.facebook.com/sagamill'}*/}
                            {/*    target={'_blank'}*/}
                            {/*>*/}
                            {/*    <IconButton aria-label="facebook">*/}
                            {/*        <Icon*/}
                            {/*            path={FacebookIcon}*/}
                            {/*            size={1}*/}
                            {/*            color="#6E6E6E99"*/}
                            {/*        />*/}
                            {/*    </IconButton>*/}
                            {/*</Link>*/}
                            {/*<Link*/}
                            {/*    href={'https://twitter.com/sagamill'}*/}
                            {/*    target={'_blank'}*/}
                            {/*>*/}
                            {/*    <IconButton aria-label="twitter">*/}
                            {/*        <Icon*/}
                            {/*            path={TwitterIcon}*/}
                            {/*            size={1}*/}
                            {/*            color="#6E6E6E99"*/}
                            {/*        />*/}
                            {/*    </IconButton>*/}
                            {/*</Link>*/}
                            {/*<Link*/}
                            {/*    href={'https://github.com/sagamill'}*/}
                            {/*    target={'_blank'}*/}
                            {/*>*/}
                            {/*    <IconButton*/}
                            {/*        aria-label="github"*/}
                            {/*        style={{marginRight: -12}}*/}
                            {/*    >*/}
                            {/*        <Icon*/}
                            {/*            path={GithubIcon}*/}
                            {/*            size={1}*/}
                            {/*            color="#6E6E6E99"*/}
                            {/*        />*/}
                            {/*    </IconButton>*/}
                            {/*</Link>*/}
                        </div>
                    </Box>
                </div>
            </>
        </div>
    );
}

function FeatureTagLayout(props) {
    return (
        <>
            <FeatureTagProvider>
                <Layout {...props} />
            </FeatureTagProvider>
        </>
    )
}

export default withRouter(FeatureTagLayout);
