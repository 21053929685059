import {FeatureTag} from "./FeatureTag";
import {BillingOperation, requestBilling} from "../../api/billingAPI";
import {
    FeatureTagContextActionKeyUpdateFeatureTags,
    FeatureTagContextKeyFeatureTags
} from "../../context/FeatureTagContextKey";

class UserManager {

    constructor(props) {

    }

    feature_tags = []

    fetchFeatureTags({dispatch}) {
        requestBilling({
            data: {
                op: BillingOperation.OpPermissionStatus.value
            }
        }).then((response) => {
            const {
                code,
                data: {
                    features
                }
            } = response.data;
            if (code === 200) {
                this.feature_tags = features;
                localStorage.setItem(FeatureTagContextKeyFeatureTags, features)
                dispatch({
                    type: FeatureTagContextActionKeyUpdateFeatureTags,
                    data: {
                        feature_tags: features
                    }
                })
                console.log(`request features success ${features}`)
            } else {
                console.log(`request features fail`)
            }
        })
    }

    hasPermission = ({feature_tag}) => {
        return this.feature_tags.includes(feature_tag.value);
    }
}

const user_manager = new UserManager();

export {user_manager}
