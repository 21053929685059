import React, {useEffect, useState} from "react";
import {Drawer, IconButton, List} from "@material-ui/core";
import {
    ArrowBack as ArrowBackIcon,
    FormatListBulleted as WatchListIcon,
    HelpOutline as FAQIcon,
    InsertDriveFile as TermsIcon,
    VerifiedUser as PrivacyIcon,
} from "@material-ui/icons";
import {useTheme} from "@material-ui/styles";
import {withRouter} from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";
import {ReactComponent as SubscriptionIcon} from "../../pages/PremiumScreener/diamond.svg";
import {ReactComponent as OptionSignalIcon} from "./OptionSignal.svg";
import {ReactComponent as StockSignalIcon} from "./StockSignal.svg";
import {ReactComponent as CryptoSignalIcon} from "./CryptoSignal.svg";
import {ReactComponent as ScreenerIcon} from "../../pages/Screener/funnel.svg";
import {ReactComponent as PremiumScreenerIcon} from "../../pages/Analysis/analytics-outline.svg";
import {ReactComponent as NotificationIcon} from "./Notification.svg";
import {ReactComponent as AnalysisIcon} from "./Analysis.svg";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
// import Dot from "./components/Dot";
// context
import {toggleSidebar, useLayoutDispatch, useLayoutState,} from "../../context/LayoutContext";

const structure = [
    {id: 15, label: "Stock Signal", link: "/app/stock_signal", icon: <StockSignalIcon/>},
    {id: 21, label: "Option Signal", link: "/app/option_signal", icon: <OptionSignalIcon/>},
    // {id: 22, label: "Crypto Signal", link: "/app/crypto_signal", icon: <CryptoSignalIcon/>},
    {id: 16, label: "Watch List", link: "/app/watch_list", icon: <WatchListIcon/>},
    {id: 17, label: "Notification List", link: "/app/notification_list", icon: <NotificationIcon/>},
    {id: 18, label: "Premium Screener", link: "/app/premium_screener", icon: <PremiumScreenerIcon/>},
    {id: 19, label: "Screener", link: "/app/screener", icon: <ScreenerIcon/>},
    {id: 20, label: "Analysis", link: "/app/analysis", icon: <AnalysisIcon/>},
    {id: 23, label: "Signal Subscription", link: "/app/signal_subscription", icon: <SubscriptionIcon/>},
    {id: 24, label: "Analysis Subscription", link: "/app/ana_subscription", icon: <SubscriptionIcon/>},

    // { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
    // {
    //   id: 1,
    //   label: "Typography",
    //   link: "/app/typography",
    //   icon: <TypographyIcon />,
    // },
    // { id: 2, label: "Tables", link: "/app/tables", icon: <TableIcon /> },
    // {
    //   id: 3,
    //   label: "Notifications",
    //   link: "/app/notifications",
    //   icon: <NotificationsIcon />,
    // },
    // {
    //   id: 4,
    //   label: "UI Elements",
    //   link: "/app/ui",
    //   icon: <UIElementsIcon />,
    //   children: [
    //     { label: "Icons", link: "/app/ui/icons" },
    //     { label: "Charts", link: "/app/ui/charts" },
    //   ],
    // },
    {id: 5, type: "divider"},
    {id: 6, type: "title", label: "HELP"},
    // { id: 7, label: "Library", link: "https://flatlogic.com/templates", icon: <LibraryIcon /> },

    // { id: 8, label: "Support", link: "https://flatlogic.com/forum", icon: <SupportIcon /> },

    {
        id: 9,
        label: "FAQ",
        link: "https://avadarfinancial.web.app/helpcenter/2019/01/09/stock-signal-faq.html",
        icon: <FAQIcon/>
    },
    {id: 25, type: "divider"},
    {id: 26, type: "title", label: "ABOUT"},
    {
        id: 27,
        label: "Terms of Use",
        link: "https://app.bounceape.com/bounce_ape_terms.html",
        icon: <TermsIcon/>
    },
    {
        id: 28,
        label: "Privacy Policy",
        link: "https://app.bounceape.com/bounce_ape_privacy.html",
        icon: <PrivacyIcon/>
    },
    // { id: 10, type: "divider" },
    // { id: 11, type: "title", label: "PROJECTS" },
    // {
    //   id: 12,
    //   label: "My recent",
    //   link: "",
    //   icon: <Dot size="small" color="warning" />,
    // },
    // {
    //   id: 13,
    //   label: "Starred",
    //   link: "",
    //   icon: <Dot size="small" color="primary" />,
    // },
    // {
    //   id: 14,
    //   label: "Background",
    //   link: "",
    //   icon: <Dot size="small" color="secondary" />,
    // },
];

function Sidebar({location}) {
    var classes = useStyles();
    var theme = useTheme();

    // global
    var {isSidebarOpened} = useLayoutState();
    var layoutDispatch = useLayoutDispatch();

    // local
    var [isPermanent, setPermanent] = useState(true);

    useEffect(function () {
        window.addEventListener("resize", handleWindowWidthChange);
        handleWindowWidthChange();
        return function cleanup() {
            window.removeEventListener("resize", handleWindowWidthChange);
        };
    });

    return (
        <Drawer
            variant={isPermanent ? "permanent" : "temporary"}
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: isSidebarOpened,
                [classes.drawerClose]: !isSidebarOpened,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: isSidebarOpened,
                    [classes.drawerClose]: !isSidebarOpened,
                }),
            }}
            open={isSidebarOpened}
        >
            <div className={classes.toolbar}/>
            <div className={classes.mobileBackButton}>
                <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
                    <ArrowBackIcon
                        classes={{
                            root: classNames(classes.headerIcon, classes.headerIconCollapse),
                        }}
                    />
                </IconButton>
            </div>
            <List className={classes.sidebarList}>
                {structure.map(link => (
                    <SidebarLink
                        key={link.id}
                        location={location}
                        isSidebarOpened={isSidebarOpened}
                        {...link}
                    />
                ))}
            </List>
        </Drawer>
    );

    // ##################################################################
    function handleWindowWidthChange() {
        var windowWidth = window.innerWidth;
        var breakpointWidth = theme.breakpoints.values.md;
        var isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent) {
            setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true);
        }
    }
}

export default withRouter(Sidebar);
