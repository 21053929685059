import React from "react";
import {FeatureTagContextActionKeyUpdateFeatureTags, FeatureTagContextKeyFeatureTags} from "./FeatureTagContextKey";

const FeatureTagStateContext = React.createContext();
const FeatureTagDispatchContext = React.createContext();

function featureTagReducer(state, action) {
    switch (action.type) {
        case FeatureTagContextActionKeyUpdateFeatureTags:
            return {...state, ...action.data};
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function useFeatureTagState() {
    var context = React.useContext(FeatureTagStateContext);
    if (context === undefined) {
        throw new Error("featureTagState must be used within a FeatureTagProvider");
    }
    return context;
}


function useFeatureTagDispatch() {
    var context = React.useContext(FeatureTagDispatchContext);
    if (context === undefined) {
        throw new Error("featureTagDispatch must be used within a FeatureTagProvider");
    }
    return context;
}

function FeatureTagProvider({children}) {
    const [state, dispatch] = React.useReducer(featureTagReducer, {
        feature_tags: localStorage.getItem(FeatureTagContextKeyFeatureTags)
    });

    return (
        <FeatureTagStateContext.Provider value={state}>
            <FeatureTagDispatchContext.Provider value={dispatch}>
                {children}
            </FeatureTagDispatchContext.Provider>
        </FeatureTagStateContext.Provider>
    );
}

export {FeatureTagProvider, useFeatureTagState, useFeatureTagDispatch}
