import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Fade, Grid, Tab, Tabs, TextField, Typography,} from "@material-ui/core";
import {withRouter} from "react-router-dom";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.png";

// context
import {loginUser, resetPasswordEmail, signUp, useUserDispatch} from "../../context/UserContext";
import {strings} from "../../i18n/Strings";
import {bounce_ape_analytics} from "../../common/Analytics/Analytics";
import {EventKey, PropertyKey} from "../../common/Analytics/AnalyticsEventKey";

const ActiveTabLogin = 0
const ActiveTabRegister = 1

const ActiveStateLoginRegister = 1
const ActiveStateForget = 2

function Login(props) {
    var classes = useStyles();

    // global
    var userDispatch = useUserDispatch();

    // local
    var [isLoading, setIsLoading] = useState(false);
    var [error, setError] = useState(null);
    var [activeTabId, setActiveTabId] = useState(0);
    var [activeStateId, setActiveStateId] = useState(1);
    var [nameValue, setNameValue] = useState("");
    var [loginValue, setLoginValue] = useState("");
    var [passwordValue, setPasswordValue] = useState("");

    useEffect(() => {

        bounce_ape_analytics.logEvent({event: EventKey.pageShow, properties: {name: "login_page_web"}})
    }, [])
    return (
        <Grid container className={classes.container}>
            <div className={classes.logotypeContainer}>
                <img src={logo} alt="logo" className={classes.logotypeImage}/>
                <Typography className={classes.logotypeText}>{strings.brand}</Typography>
            </div>
            <div className={classes.formContainer}>
                {
                    /// login & register
                    activeStateId === ActiveStateLoginRegister && (
                        <div className={classes.form}>
                            <Tabs
                                value={activeTabId}
                                onChange={(e, id) => setActiveTabId(id)}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label="Login" classes={{root: classes.tab}}/>
                                <Tab label="New User" classes={{root: classes.tab}}/>
                            </Tabs>
                            {activeTabId === ActiveTabLogin && (
                                <React.Fragment>
                                    <Typography variant="h1" className={classes.greeting}>
                                        Welcome!
                                    </Typography>
                                    {/* Google Sign in */}
                                    {/*<Button size="large" className={classes.googleButton}>*/}
                                    {/*    <img src={google} alt="google" className={classes.googleIcon}/>*/}
                                    {/*    &nbsp;Sign in with Google*/}
                                    {/*</Button>*/}
                                    {/*<div className={classes.formDividerContainer}>*/}
                                    {/*    <div className={classes.formDivider}/>*/}
                                    {/*    <Typography className={classes.formDividerWord}>or</Typography>*/}
                                    {/*    <div className={classes.formDivider}/>*/}
                                    {/*</div>*/}
                                    <Fade in={error}>
                                        <Typography color="secondary" className={classes.errorMessage}>
                                            Something is wrong with your login or password :(
                                        </Typography>
                                    </Fade>
                                    <TextField
                                        id="email"
                                        InputProps={{
                                            classes: {
                                                underline: classes.textFieldUnderline,
                                                input: classes.textField,
                                            },
                                        }}
                                        value={loginValue}
                                        onChange={e => setLoginValue(e.target.value)}
                                        margin="normal"
                                        placeholder="Email Address"
                                        type="email"
                                        fullWidth
                                    />
                                    <TextField
                                        id="password"
                                        InputProps={{
                                            classes: {
                                                underline: classes.textFieldUnderline,
                                                input: classes.textField,
                                            },
                                        }}
                                        value={passwordValue}
                                        onChange={e => setPasswordValue(e.target.value)}
                                        margin="normal"
                                        placeholder="Password"
                                        type="password"
                                        fullWidth
                                    />
                                    <div className={classes.formButtons}>
                                        {isLoading ? (
                                            <CircularProgress size={26} className={classes.loginLoader}/>
                                        ) : (
                                            <Button
                                                disabled={
                                                    loginValue.length === 0 || passwordValue.length === 0
                                                }
                                                onClick={() =>
                                                    loginUser(
                                                        userDispatch,
                                                        loginValue,
                                                        passwordValue,
                                                        props.history,
                                                        setIsLoading,
                                                        setError,
                                                    )
                                                }
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                            >
                                                Login
                                            </Button>
                                        )}
                                        <Button
                                            color="primary"
                                            size="large"
                                            className={classes.forgetButton}
                                            onClick={() => {setActiveStateId(ActiveStateForget)}}
                                        >
                                            Forget Password
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )}
                            {activeTabId === ActiveTabRegister && (
                                <React.Fragment>
                                    <Typography variant="h1" className={classes.greeting}>
                                        Welcome!
                                    </Typography>
                                    <Typography variant="h2" className={classes.subGreeting}>
                                        Create your account
                                    </Typography>
                                    <Fade in={error}>
                                        <Typography color="secondary" className={classes.errorMessage}>
                                            Something is wrong with your login or password :(
                                        </Typography>
                                    </Fade>
                                    <TextField
                                        id="name"
                                        InputProps={{
                                            classes: {
                                                underline: classes.textFieldUnderline,
                                                input: classes.textField,
                                            },
                                        }}
                                        value={nameValue}
                                        onChange={e => setNameValue(e.target.value)}
                                        margin="normal"
                                        placeholder="Full Name"
                                        type="text"
                                        fullWidth
                                    />
                                    <TextField
                                        id="email"
                                        InputProps={{
                                            classes: {
                                                underline: classes.textFieldUnderline,
                                                input: classes.textField,
                                            },
                                        }}
                                        value={loginValue}
                                        onChange={e => setLoginValue(e.target.value)}
                                        margin="normal"
                                        placeholder="Email Address"
                                        type="email"
                                        fullWidth
                                    />
                                    <TextField
                                        id="password"
                                        InputProps={{
                                            classes: {
                                                underline: classes.textFieldUnderline,
                                                input: classes.textField,
                                            },
                                        }}
                                        value={passwordValue}
                                        onChange={e => setPasswordValue(e.target.value)}
                                        margin="normal"
                                        placeholder="Password"
                                        type="password"
                                        fullWidth
                                    />
                                    <div className={classes.creatingButtonContainer}>
                                        {isLoading ? (
                                            <CircularProgress size={26}/>
                                        ) : (
                                            <Button
                                                onClick={() =>
                                                    signUp(
                                                        userDispatch,
                                                        loginValue,
                                                        passwordValue,
                                                        nameValue,
                                                        setIsLoading,
                                                        setError,
                                                    )
                                                }
                                                disabled={
                                                    loginValue.length === 0 ||
                                                    passwordValue.length === 0 ||
                                                    nameValue.length === 0
                                                }
                                                size="large"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.createAccountButton}
                                            >
                                                Create your account
                                            </Button>
                                        )}
                                    </div>
                                    {/* Google Sign up*/}
                                    {/*<div className={classes.formDividerContainer}>*/}
                                    {/*    <div className={classes.formDivider}/>*/}
                                    {/*    <Typography className={classes.formDividerWord}>or</Typography>*/}
                                    {/*    <div className={classes.formDivider}/>*/}
                                    {/*</div>*/}
                                    {/*<Button*/}
                                    {/*    size="large"*/}
                                    {/*    className={classnames(*/}
                                    {/*        classes.googleButton,*/}
                                    {/*        classes.googleButtonCreating,*/}
                                    {/*    )}*/}
                                    {/*>*/}
                                    {/*    <img src={google} alt="google" className={classes.googleIcon}/>*/}
                                    {/*    &nbsp;Sign in with Google*/}
                                    {/*</Button>*/}
                                </React.Fragment>
                            )}
                        </div>
                    )
                }
                {
                    /// forget
                    activeStateId === ActiveStateForget && (
                        <div className={classes.form}>
                            <React.Fragment>
                                <Typography variant="h2" className={classes.subGreeting}>
                                    Reset Password
                                </Typography>
                                <Fade in={error}>
                                    <Typography color="secondary" className={classes.errorMessage}>
                                        Something is wrong with your login or password :(
                                    </Typography>
                                </Fade>
                                <TextField
                                    id="email"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={loginValue}
                                    onChange={e => setLoginValue(e.target.value)}
                                    margin="normal"
                                    placeholder="Email Address"
                                    type="email"
                                    fullWidth
                                />
                                <div className={classes.formButtons}>
                                    {isLoading ? (
                                        <CircularProgress size={26} className={classes.loginLoader}/>
                                    ) : (
                                        <Button
                                            disabled={
                                                loginValue.length === 0
                                            }
                                            onClick={() =>
                                                resetPasswordEmail(
                                                    userDispatch,
                                                    loginValue,
                                                    props.history,
                                                    setIsLoading,
                                                    setError,
                                                )
                                            }
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                        >
                                            SEND
                                        </Button>
                                    )}
                                    <Button
                                        color="primary"
                                        size="large"
                                        className={classes.forgetButton}
                                        onClick={() => {setActiveStateId(ActiveStateLoginRegister)}}
                                    >
                                        Back to login
                                    </Button>
                                </div>
                            </React.Fragment>
                        </div>
                    )
                }
                <Typography color="primary" className={classes.copyright}>
                    © {new Date().getFullYear()} <a style={{textDecoration: 'none', color: 'inherit'}}
                                                         href={process.env.REACT_APP_HOME_URL} rel="noopener noreferrer"
                                                         target="_blank">SAGAMILL</a> PTE. LTD. All rights reserved.
                </Typography>
            </div>
        </Grid>
    );
}

export default withRouter(Login);
