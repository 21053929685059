import axios from "axios";
import {getFirebaseUser} from "../firebase/FirebaseInitializer";

const getJwtToken = () => {
    return getFirebaseUser().then((user) => {
        return user.getIdToken();
    })
};

const BillingOperation = Object.freeze({
    OpCreate: {
        value: 1
    },
    OpApproved: {
        value: 2
    },
    OpCancel: {
        value: 3
    },
    OpSubscriptionStatus: {
        value: 4
    },
    OpPermissionStatus: {
        value: 5
    },
    OpCancelSubscription: {
        value: 6
    },
    OpTransactions: {
        value: 7
    },
    OpProfileSubscriptionList: {
        value: 8
    }
});

const requestBilling = ({data}) => {
    return getJwtToken().then((jwtToken) => {
        return axios({
            method: 'post',
            url: process.env.REACT_APP_BILLING_URL,
            headers: {
                Authorization: `Bearer ${jwtToken}`
            },
            data: data
        });
    })
};

export {BillingOperation, requestBilling, getJwtToken}
