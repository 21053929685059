import {CircularProgress, Paper} from "@material-ui/core";
import React, {lazy, Suspense} from "react";
import {Route, Switch} from "react-router-dom";
import useStyles from "./styles.js";
import WrapToastConatiner from "../../common/Toast/WrapToastConatiner";

const Profile = lazy(() => import("../Profile/Profile"));
const TransactionList = lazy(() => import("../TransactionList/TransactionList"));

export default function ProfileMain(props) {
    const classes = useStyles();
    return (
        <>
            <div className={classes.root}>
                <WrapToastConatiner/>
                <Paper className={classes.paper}>
                    <Suspense fallback={<div><CircularProgress/></div>}>
                        <Switch>
                            <Route path="/profile/main" component={Profile}/>
                            <Route path="/profile/transaction_list/:subscription_id" component={TransactionList}/>
                        </Switch>
                    </Suspense>
                </Paper>
            </div>
        </>
    )
}
