const UserContextKeyIsAuthenticated = "isAuthenticated";
const UserContextKeyAccessToken = "access_token";
const UserContextKeyUser = "user";

const UserReduceActionKeyLoginSuccess = "LOGIN_SUCCESS";
const UserReduceActionKeySignOutSuccess = "SIGN_OUT_SUCCESS";
const UserReduceActionKeyLoginFailure = "LOGIN_FAILURE";


export {
    UserContextKeyAccessToken,
    UserContextKeyUser,
    UserReduceActionKeyLoginSuccess,
    UserReduceActionKeySignOutSuccess,
    UserReduceActionKeyLoginFailure,
};
